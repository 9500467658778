import React from 'react';

import { Page } from '@tager/web-components';

import useSettingItem from '@/hooks/useSettingItem';
import Layout from '@/components/Layout';
import { Error404 } from '@/modules/Error';

function NotFoundPage() {
  const pageTitle = useSettingItem('PAGE_TITLE_NOT_FOUND');

  return (
    <Page title={pageTitle}>
      <Layout config={{ header: { colorVariant: 'green', isMainBg: false } }}>
        <Error404 />
      </Layout>
    </Page>
  );
}

export default NotFoundPage;
