import { StoreDispatch } from '@/store/store';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { loadCities } from '@/store/reducers/cities';

export function getSharedThunkList(dispatch: StoreDispatch) {
  return [
    dispatch(loadCities()),
    dispatch(getMenuItemListThunk('header')),
    dispatch(getMenuItemListThunk('footer')),
    dispatch(getSettingItemListThunk()),
  ] as const;
}
