import React from 'react';
import { useRouter } from 'next/router';

import { Page } from '@tager/web-components';
import { cookie } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { CustomApp_PageContext } from '@/typings/hocs';
import {
  getPageByPathThunk,
  getPageListThunk,
  selectPageByPath,
} from '@/store/reducers/tager/pages';
import { getPageModuleByTemplate } from '@/services/pageModules';
import { convertErrorToProps, convertSlugToPath } from '@/utils/common';
import { getSharedThunkList } from '@/utils/thunks';
import { useTypedSelector } from '@/store/store';
import NotFoundPage from '@/pages/404';
import {
  convertSeoParamsWithRegionToPageProps,
  getPageBaseUrl,
  getPageDataByUrl,
  redirect,
} from '@/utils/regions';
import {
  citiesActions,
  selectActiveCity,
  selectCities,
} from '@/store/reducers/cities';
import { geolocation } from '@/services/requests/misc';

type Props =
  | {
      pageType: 'DYNAMIC_PAGE';
      template: string;
      namespacesRequired?: Array<string>;
    }
  | {
      pageType: 'NOT_FOUND';
      namespacesRequired?: Array<string>;
    }
  | {
      pageType: 'ERROR';
      error: Error;
      namespacesRequired?: Array<string>;
    };

function DynamicPage(props: Props) {
  const router = useRouter();
  const currentPath = convertSlugToPath(router.query.slug);
  const cities = useTypedSelector(selectCities);
  const activeCity = useTypedSelector(selectActiveCity);

  const baseUrl = getPageBaseUrl(currentPath, cities) || '';

  const page = useTypedSelector((state) => selectPageByPath(state, baseUrl));

  if (props.pageType === 'NOT_FOUND') {
    return <NotFoundPage />;
  }

  if (props.pageType === 'ERROR') {
    return <ErrorPage {...convertErrorToProps(props.error)} />;
  }

  const foundPageModule = getPageModuleByTemplate(props.template);
  const pageElement = React.createElement(foundPageModule.component);

  if (!page) {
    return null;
  }

  return (
    <Page
      {...convertSeoParamsWithRegionToPageProps(page?.seoParams, activeCity)}
    >
      {pageElement}
    </Page>
  );
}

DynamicPage.getInitialProps = async (
  context: CustomApp_PageContext
): Promise<Props> => {
  const { store, query, req, res } = context;
  let currentPath = convertSlugToPath(query.slug);

  try {
    const [pageList] = await Promise.all([
      store.dispatch(getPageListThunk()),
      ...getSharedThunkList(store.dispatch),
    ]);

    const cities = store.getState().cities.data;

    const pageData = getPageDataByUrl(pageList, currentPath, cities);

    if (!pageData) {
      if (context.res) {
        context.res.statusCode = 404;
      }

      return {
        pageType: 'NOT_FOUND',
        namespacesRequired: ['common', 'error-pages', 'auth'],
      };
    }

    const { baseUrl, pageModule, pageCity } = pageData;

    const cookieCityId = cookie.get('cityId', req);

    let userCitySelected = pageCity;
    if (cookieCityId) {
      userCitySelected =
        cities.find((item) => item.id === +cookieCityId) || null;
    } else {
      const forwarded = req?.headers['x-forwarded-for'];
      const ipAddress =
        typeof forwarded === 'string'
          ? forwarded.split(/, /)[0]
          : req?.socket.remoteAddress;

      if (ipAddress) {
        try {
          const userCity = await geolocation(ipAddress);
          if (userCity) {
            userCitySelected =
              cities.find((item) => item.id === +userCity.id) || null;
          }
        } catch (e) {}
      }

      if (!userCitySelected) {
        userCitySelected = pageCity;
      }

      if (userCitySelected) {
        cookie.set('cityId', String(userCitySelected.id), res);
      }
    }

    if (userCitySelected) {
      store.dispatch(citiesActions.setActiveCity(userCitySelected));
    }

    if (pageModule) {
      if (pageModule.template === '') {
        if (cities[0].id !== pageCity?.id) {
          const requestUrl = context.req?.url || '';
          const queryString = requestUrl.includes('?')
            ? requestUrl.substring(requestUrl.indexOf('?'))
            : '';
          redirect(baseUrl + queryString, res);
        }
      } else if (
        userCitySelected &&
        pageCity &&
        pageCity.id !== userCitySelected.id
      ) {
        const requestUrl = context.req?.url || '';
        const queryString = requestUrl.includes('?')
          ? requestUrl.substring(requestUrl.indexOf('?'))
          : '';

        redirect(
          (userCitySelected.isDefault ? '' : '/' + userCitySelected.urlAlias) +
            baseUrl +
            queryString,
          res
        );
      }
    }

    await Promise.all([
      store.dispatch(getPageByPathThunk(baseUrl)),
      pageModule.getInitialProps
        ? pageModule.getInitialProps(context)
        : Promise.resolve(),
    ]);

    return {
      pageType: 'DYNAMIC_PAGE',
      template: pageModule.template,
      namespacesRequired: ['common', 'home', 'auth', 'office'],
    };
  } catch (error) {
    return {
      pageType: 'ERROR',
      error: error as Error,
      namespacesRequired: ['common', 'error-pages', 'auth'],
    };
  }
};

export default DynamicPage;
