import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import StaticBlock from '@/components/__old__/StaticBlock';
import ContentContainer from '@/components/ContentContainer';
import { useTranslation } from '@/i18n';

export function Error404() {
  const { t } = useTranslation();

  return (
    <SectionDefault>
      <ContentContainer>
        <StaticBlock>
          <h2>{t('404_error_text')}</h2>
          <p>
            <a href="/">{t('to_main')}</a>
          </p>
        </StaticBlock>
      </ContentContainer>
    </SectionDefault>
  );
}

export function Error() {
  const { t } = useTranslation();

  return (
    <SectionDefault>
      <ContentContainer>
        <StaticBlock>
          <h2>{t('server_error_text')}</h2>
          <p>
            <a href="/">{t('to_main')}</a>
          </p>
        </StaticBlock>
      </ContentContainer>
    </SectionDefault>
  );
}

export function ErrorOrder() {
  const { t } = useTranslation();

  return (
    <Container>
      <Main>
        <SectionDefault>
          <ContentContainer>
            <StaticBlockWrapper>
              <StaticBlock>
                <h2>{t('order_error_text')}</h2>
                <p>
                  <a href="/">{t('to_main')}</a>
                </p>
              </StaticBlock>
            </StaticBlockWrapper>
          </ContentContainer>
        </SectionDefault>
      </Main>
    </Container>
  );
}

const SectionDefault = styled.section`
  padding-top: 120px;
  padding-bottom: 150px;

  ${media.mobile(css`
    padding-bottom: 50px;
  `)}
`;

const StaticBlockWrapper = styled.div`
  text-align: center;
`;

const Container = styled.div`
  background-color: #fff;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;
